@import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
@import 'react-pdf/dist/esm/Page/TextLayer.css';
@import 'prismjs/themes/prism-tomorrow.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: #f3f4f6;
}

.highlight {
  font-style: italic;
}

.custom-tabs .ant-tabs-tab {
  white-space: normal !important;
  text-align: left;
  padding: 8px;
}

.custom-tabs .ant-tabs-tab-btn {
  white-space: normal !important;
}

.custom-tabs .ant-tabs-left-bar .ant-tabs-tab {
  max-width: 220px;
}

.diff-view {
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.diff-line {
  margin: 0;
  padding: 2px 5px;
}

.diff-added {
  background-color: #e6ffed;
  color: #24292e;
}

.diff-removed {
  background-color: #ffeef0;
  color: #24292e;
}

.custom-steps .ant-steps-item-container {
  display: flex;
  align-items: flex-start;
}

.custom-steps .ant-steps-item-icon {
  margin-right: 8px;
}

.custom-steps .ant-steps-item-content {
  flex: 1;
  min-width: 0;
}

@media (max-width: 640px) {
  .custom-steps .ant-steps-item-title {
    font-size: 14px;
    line-height: 1.2;
  }
  .custom-steps .ant-steps-item-description {
    font-size: 12px;
  }
}

.confirmation-modal .ant-modal-content {
  max-width: 90vw;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 640px) {
  .confirmation-modal .ant-modal-content {
    max-width: 500px;
  }
}

.confirmation-modal .ant-modal-body {
  padding: 16px;
}

@media (min-width: 640px) {
  .confirmation-modal .ant-modal-body {
    padding: 24px;
  }
}

.confirmation-modal .ant-modal-footer {
  padding: 16px;
}

@media (min-width: 640px) {
  .confirmation-modal .ant-modal-footer {
    padding: 24px;
  }
}

.html-viewer {
  max-width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .html-viewer .controls {
    justify-content: center;
  }
}

.custom-message {
  z-index: 1000;
  word-break: break-all;
  max-width: 80vw;
}

.blog-content pre code {
  color: #f8f8f2;
  background: none;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

.blog-content pre {
  background: #282a36;
  border-radius: 0.5em;
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

.blog-content {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
  font-weight: 400;
}

.blog-content p,
.blog-content .prose p {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
  margin-top: 0.95em !important;
  margin-bottom: 0.95em !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 1024px) {
  .blog-content pre {
    margin-left: -10%;
    margin-right: -10%;
    width: 120%;
  }
}

/* For even wider screens, we can extend further */
@media (min-width: 1536px) {
  .blog-content pre {
    margin-left: -30%;
    margin-right: -30%;
    width: 160%;
  }
}

/* Token colors */
.blog-content .token.comment,
.blog-content .token.prolog,
.blog-content .token.doctype,
.blog-content .token.cdata {
  color: #6272a4;
}

.blog-content .token.punctuation {
  color: #f8f8f2;
}

.blog-content .token.namespace {
  opacity: 0.7;
}

.blog-content .token.property,
.blog-content .token.tag,
.blog-content .token.constant,
.blog-content .token.symbol,
.blog-content .token.deleted {
  color: #ff79c6;
}

.blog-content .token.boolean,
.blog-content .token.number {
  color: #bd93f9;
}

.blog-content .token.selector,
.blog-content .token.attr-name,
.blog-content .token.string,
.blog-content .token.char,
.blog-content .token.builtin,
.blog-content .token.inserted {
  color: #50fa7b;
}

.blog-content .token.operator,
.blog-content .token.entity,
.blog-content .token.url,
.blog-content .language-css .token.string,
.blog-content .style .token.string,
.blog-content .token.variable {
  color: #f8f8f2;
}

.blog-content .token.atrule,
.blog-content .token.attr-value,
.blog-content .token.function,
.blog-content .token.class-name {
  color: #f1fa8c;
}

.blog-content .token.keyword {
  color: #8be9fd;
}

.blog-content .token.regex,
.blog-content .token.important {
  color: #ffb86c;
}

.blog-content .token.important,
.blog-content .token.bold {
  font-weight: bold;
}

.blog-content .token.italic {
  font-style: italic;
}

.blog-content .token.entity {
  cursor: help;
}

.blog-content table {
  width: 100%;
  margin: 1em 0;
  border-collapse: collapse;
}

.blog-content table img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.blog-content table td {
  border: 1px solid #e5e7eb;
  padding: 8px;
  text-align: center;
}

.blog-content table td em {
  display: block;
  margin-top: 0.5em;
  font-style: italic;
  color: #666;
}
